// Flatly 4.5.0
// Bootswatch

//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ecf0f1 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #b4bcc2 !default;
$gray-600: #95a5a6 !default;
$gray-700: #7b8a8b !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

// UKRI palette https://www.ukri.org/about-us/brand-guidelines/ page 26
// 1. full palette
// a. ukri blue
$ukri_blue:                 #2E2D62 !default;

// b. ukri bright palette   $ukri__:         # !default;
$ukri_bright_orange:        #FF6900 !default;
$ukri_bright_ochre:         #FF9D1B !default;
$ukri_bright_yellow:        #FBBB10 !default;
$ukri_bright_green1:        #67C04D !default;
$ukri_bright_green2:        #00A788 !default;
$ukri_bright_green3:        #34D5AE !default;
$ukri_bright_blue1:         #00BED5 !default;
$ukri_bright_blue2:         #1E5DF8 !default;
$ukri_bright_pink:          #E355EC !default;
$ukri_bright_mauve:         #BE2BBB !default;
$ukri_bright_red1:          #FF5A5A !default;
$ukri_bright_red2:          #E94D36 !default;

// c. ukri deep palette
$ukri_deep_orange:          #C13D33 !default;
$ukri_deep_ochre:           #D77900 !default;
$ukri_deep_yellow:          #F08900 !default;
$ukri_deep_green1:          #3E863E !default;
$ukri_deep_green2:          #005E54 !default;
$ukri_deep_green3:          #16978A !default;
$ukri_deep_blue1:           #008AAD !default;
$ukri_deep_blue2:           #003088 !default;
$ukri_deep_pink:            #923D9D !default;
$ukri_deep_mauve:           #8A1A9B !default;
$ukri_deep_red1:            #CB3564 !default;
$ukri_deep_red2:            #A91B2E !default;

// d. ukri neutral palette
$ukri_neutral_grey:         #676767 !default;

// 2. UKRI condensed highlight palette
$ukri_condensed_ochre:      $ukri_bright_ochre !default;
$ukri_condensed_yellow:     $ukri_bright_yellow !default;
$ukri_condensed_green1:     $ukri_bright_green1 !default;
$ukri_condensed_green2:     $ukri_bright_green2 !default;
$ukri_condensed_blue1:      $ukri_bright_blue1 !default;
$ukri_condensed_blue2:      $ukri_bright_blue2 !default;
$ukri_condensed_mauve:      $ukri_bright_mauve !default;
$ukri_condensed_red:        $ukri_bright_red2 !default

// UKRI JASMIN assignment

$primary:       $ukri_deep_blue2 !default;
$secondary:     $ukri_deep_green1 !default;

$success:       $ukri_bright_green1 !default;
$info:          $ukri_bright_blue2 !default;
$warning:       $ukri_bright_orange !default;
$danger:        $ukri_deep_red2 !default;

$light:         $gray-500 !default;
$dark:          $ukri_blue !default;

// Options

$enable-rounded: false !default;

$yiq-contrasted-threshold:  175 !default;

// Links

$link-color:                $secondary !default; //was $success

$body-color:                $ukri_blue !default;

// Fonts

$font-family-sans-serif:      "BoosterNextFY-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$font-size-base:              0.9375rem !default;

$h1-font-size:                3rem !default;
$h2-font-size:                2.5rem !default;
$h3-font-size:                2rem !default;

// Tables

$table-accent-bg:             $gray-200 !default;
$table-hover-color:           $gray-700;

// Dropdowns

$dropdown-link-color:               $gray-700 !default;
$dropdown-link-hover-color:         $white !default;
$dropdown-link-hover-bg:            $success !default;

// Navs

$nav-link-padding-y:                .5rem !default !default;
$nav-link-padding-x:                2rem !default;
$nav-link-disabled-color:           $gray-600 !default !default;

$nav-tabs-border-color:             $gray-200 !default;

// Navbar

$navbar-padding-y:                  1rem !default;

$navbar-dark-color:                 $white !default;
$navbar-dark-hover-color:           $dark !default;

// Pagination

$pagination-color:                  $white !default;
$pagination-bg:                     $primary !default;
$pagination-border-width:           0 !default;
$pagination-border-color:           transparent !default;

$pagination-hover-color:            $white !default;
$pagination-hover-bg:               $secondary !default;
$pagination-hover-border-color:     transparent !default;

$pagination-active-bg:              $pagination-hover-bg !default;
$pagination-active-border-color:    transparent !default;

$pagination-disabled-color:         $gray-400 !default;
$pagination-disabled-bg:            $gray-500 !default;
$pagination-disabled-border-color:  transparent !default;

// List group

$list-group-hover-bg:               $gray-200 !default;

$list-group-disabled-bg:            $gray-200 !default;

// Close

$close-color:                       $white !default;
$close-text-shadow:                 none !default;

// Border radius

$border-radius-base:        0px !default;
$border-radius-large:       0px !default;
$border-radius-small:       0px !default;
