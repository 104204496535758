// Reduce the thickness of the navbar.
.navbar.sticky-top {
  padding: 4px 4px;
  margin-bottom: 4px;
}

// Create a "steps" indicator.
// This should be a list element, and will render
// accross the screen with arrows.
.steps {
  @extend .pagination;
  align-items: stretch;

  li {
    flex: auto;
    position: relative;
    padding: $pagination-padding-y $pagination-padding-x;
    text-align: center;
    color: $pagination-color;
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;

    // Add a right arrow to the elements.
    &:not(:last-child):after {
      content: "";
      position: absolute;
      right: -2rem;
      height: 0px;
      top: 0;
      bottom: 0;
      margin: auto;
      border: 1rem solid transparent;
      border-left-color: #fff;
      z-index: 2;
    }
  }
}

//Create a class for the site footer with a custom background color.
.site-footer {
  background-color: $gray-200;
}
